import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Newproject = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div>
      <h2>Yeni Proje Oluştur</h2>
      {/* Proje oluşturma formu buraya gelecek */}
    </div>
  );
};

export default Newproject;
