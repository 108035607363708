import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Newproject from '../pages/Newproject';
import Myprojects from '../pages/Myprojects';
import PendingApproval from '../pages/PendingApproval'; // PendingApproval bileşenini içe aktar
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [emailVerificationPending, setEmailVerificationPending] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_VALIDATE_URL;
  const verifyEmailUrl = process.env.REACT_APP_SEND_VERIFICATION_EMAIL_URL;  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signin');
      return;
    }

    // Token geçerliliğini kontrol et
    const validateToken = async () => {
      try {
        const response = await fetch(`${apiUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (response.ok && data.status_code === 200) {
          if (data.email_verify_pending) {
            setEmailVerificationPending(true);
          }
        } else {
          localStorage.removeItem('token');
          navigate('/signin');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/signin');
      }
    };

    validateToken();
  }, [navigate, apiUrl]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  // Email doğrulama isteği gönderme fonksiyonu
  const handleEmailVerification = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${verifyEmailUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        alert("Email doğrulama talebiniz gönderildi.");
      } else {
        alert("Email doğrulama başarısız oldu.");
      }
    } catch (error) {
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  return (
    <div className="w-100">
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-3">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => setActiveTab('newproject')} disabled={emailVerificationPending}>
              Yeni Proje
            </Nav.Link>
            <Nav.Link onClick={() => setActiveTab('myprojects')} disabled={emailVerificationPending}>
              Projelerim
            </Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown title={<FaUserCircle style={{ fontSize: '1.5rem' }} />} id="user-nav-dropdown" align="end">
              <NavDropdown.Item onClick={() => setActiveTab('PendingApproval')}>Onay Bekleyen Kullanıcılar</NavDropdown.Item>
              <NavDropdown.Item onClick={() => alert('Ayarlar sayfası!')}>Ayarlar</NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>Çıkış Yap</NavDropdown.Item> 
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="w-100">
        {activeTab === 'newproject' && <Newproject />}
        {activeTab === 'myprojects' && <Myprojects />}
        {activeTab === 'PendingApproval' && <PendingApproval />} {/* PendingApproval bileşenini ekleyin */}
        {!activeTab && (
          <div className="text-center">
            {emailVerificationPending ? (
              <Alert variant="warning" className="mx-auto" style={{ maxWidth: '600px' }}>
                <Alert.Heading>Email Adresi Doğrulaması Bekleniyor!</Alert.Heading>
                <p>
                  Email adresi henüz doğrulanmamış, doğrulamak için{' '}
                  <span onClick={handleEmailVerification} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>
                    tıklayınız
                  </span>.
                </p>
              </Alert>
            ) : (
              <p>Lütfen bir seçenek seçin.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
