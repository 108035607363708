import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const MainPage = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/signup'); // signup.js sayfasına yönlendirme
  };

  const handleLogin = () => {
    navigate('/signin'); // login.js sayfasına yönlendirme
  };

  return (
    <div className="container-fluid bg-light text-dark min-vh-100 d-flex flex-column justify-content-center align-items-center">
      <div className="text-center mb-5">
        <h1 className="display-4">Mekanik Hesaplamalar Uygulamasına Hoş Geldiniz</h1>
        <p className="lead">Projenize ait hesaplamalarınızı kolayca yapabileceğiniz modern bir platform.</p>
      </div>

      <div className="d-flex gap-3 mb-5">
        <button className="btn btn-primary btn-lg" onClick={handleSignUp}>Üye Ol</button>
        <button className="btn btn-outline-secondary btn-lg" onClick={handleLogin}>Giriş Yap</button>
      </div>

      <div className="row w-100 text-center">
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Hızlı Hesaplamalar</h5>
              <p className="card-text">Çeşitli mekanik hesaplamaları saniyeler içinde yapın.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Kullanıcı Dostu Arayüz</h5>
              <p className="card-text">Kolay ve sezgisel bir arayüzle hesaplamalarınızı yönetin.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Veri Güvenliği</h5>
              <p className="card-text">Verileriniz her zaman güvenli ve koruma altında.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
