import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import Mainpage from './pages/Mainpage';
import Verifyemail from './pages/Verifyemail';
import Verifyforgotpassword from './pages/Verifyforgotpassword';
import Forgotpassword from './pages/Forgotpassword';
import ProtectedRoute from './pages/ProtectedRoute'; // ProtectedRoute bileşenini içe aktarın

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Ana sayfa rotası */}
          <Route path="/" element={<Mainpage />} />
          {/* Giriş yap sayfası rotası */}
          <Route path="/signin" element={<Login />} />
          {/* Üye ol sayfası rotası */}
          <Route path="/signup" element={<Signup />} />
          {/* Mail doğrulama sayfası rotası */}
          <Route path="/verifyemail" element={<Verifyemail />} />
          {/* Şifre Sıfırlama sayfası rotası */}
          <Route path="/verifyforgotpassword" element={<Verifyforgotpassword />} />
          {/* Şifre Sıfırlama Linki gönderme sayfası rotası */}
         <Route path="/forgotpassword" element={<Forgotpassword />} />
          {/* Korunan rota - Dashboard */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
