// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Token'ı yerel depolamadan alın

  if (!token) {
    // Token yoksa giriş sayfasına yönlendir
    return <Navigate to="/signin" replace />;
  }

  // Token varsa bileşeni render et
  return children;
};

export default ProtectedRoute;
