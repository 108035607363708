import React, { useEffect, useState } from 'react';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' veya 'error'
  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token'); // URL'den token'ı al
  const verifyEmailUrl = process.env.REACT_APP_VERIFICATION_EMAIL_URL;  

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${verifyEmailUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        const data = await response.json();
        if (response.ok) {
          setMessage(data.message || 'Email doğrulama başarılı.');
          setMessageType('success');
        } else {
          setMessage(data.message || 'Bir hata oluştu.');
          setMessageType('error');
        }
      } catch (error) {
        setMessage('Bir hata oluştu, lütfen tekrar deneyin.');
        setMessageType('error');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setMessage('Geçersiz token.');
      setMessageType('error');
      setLoading(false);
    }
  }, [token, verifyEmailUrl]);

  if (loading) {
    return <p>Doğrulama işlemi yapılıyor...</p>;
  }

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Doğrulama Sonucu</h1>
      <div style={{
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        backgroundColor: messageType === 'success' ? '#d4edda' : '#f8d7da',
        color: messageType === 'success' ? '#155724' : '#721c24',
        border: `1px solid ${messageType === 'success' ? '#c3e6cb' : '#f5c6cb'}`,
        marginBottom: '20px'
      }}>
        {message}
      </div>
      <div style={{ textAlign: 'center' }}>
        <a href="/" style={{
          textDecoration: 'none',
          color: '#007bff',
          fontSize: '16px',
          marginRight: '20px'
        }}>Anasayfa'ya dön</a>
        <button onClick={() => window.close()} style={{
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: 'pointer'
        }}>Kapat</button>
      </div>
    </div>
  );
};

export default VerifyEmail;
