import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Yönlendirme için import et

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // useNavigate hook'u ile yönlendirme işlemi

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      setError('Lütfen geçerli bir e-posta adresi giriniz.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SEND_FORGOT_PASSWORD_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      // Hem response.ok hem de API'den dönen status_code kontrolü yap
      if (response.ok && data.status_code === 200) {
        setMessage('Şifre sıfırlama e-postası gönderildi.');
        setError('');

        // 3 saniye sonra giriş ekranına yönlendirme
        setTimeout(() => {
          navigate('/'); // Giriş ekranına yönlendirme
        }, 2000);
      } else {
        setError(data.message || 'Bir hata oluştu.');
        setMessage(''); // Hatalı işlem olduğunda başarı mesajını sıfırla
      }
    } catch (error) {
      setError('Bir hata oluştu, lütfen tekrar deneyin.');
      setMessage(''); // Hatalı işlem olduğunda başarı mesajını sıfırla
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
      <Form onSubmit={handleForgotPassword} className="w-50">
        <h2>Şifrenizi Sıfırlayın</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>E-posta Adresiniz</Form.Label>
          <Form.Control
            type="email"
            placeholder="E-posta adresinizi girin"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary" className="mt-3">
          Şifre Sıfırlama Linki Gönder
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPassword;
