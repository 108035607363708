import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap'i içe aktar
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Yükleme durumunu takip etmek için state ekleyin
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Yükleme durumu true olarak ayarlayın

    try {
      const apiUrl = process.env.REACT_APP_SIGNIN_URL;
      const response = await fetch(`${apiUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Sunucuya bağlanırken bir hata oluştu.');
      }

      const data = await response.json();

      if (data.jwt) {
        localStorage.setItem('token', data.jwt);
        navigate('/dashboard');
      } else {
        setError(data.message || 'Bilinmeyen bir hata oluştu.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // API cevabı geldiğinde veya hata oluştuğunda yükleme durumunu false olarak ayarlayın
    }
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 bg-dark">
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto">
          <div className="bg-white p-4 rounded shadow-sm">
            <h2 className="text-center mb-4">Giriş Yap</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>E-posta:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-posta adresinizi girin"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="password" className="mb-4 position-relative">
                <Form.Label>Şifre:</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Şifrenizi girin"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

              </Form.Group>
              <Form.Group className="mb-4 position-relative" align="end">
               <a href="/forgotpassword" className="text-primary text-decoration-none" >
                    Şifrenizi mi unuttunuz?
                  </a> 
                </Form.Group>
                <Form.Group className="mb-4 position-relative"> 
              <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Giriş Yap'}
              </Button>
              </Form.Group>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
