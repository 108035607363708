import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'; 
import { useNavigate } from 'react-router-dom'; 

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    productType: 'Demo',
    password: '',
    confirmPassword: '',
    captchaResult: '',
    userCaptchaInput: '',
  });

  const [captchaNumbers, setCaptchaNumbers] = useState({
    num1: 0,
    num2: 0,
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

   
  useEffect(() => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaNumbers({ num1, num2 });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const captchaCorrect = captchaNumbers.num1 + captchaNumbers.num2;
    if (parseInt(formData.userCaptchaInput) !== captchaCorrect) {
      setError('Doğrulama işlemi başarısız. Lütfen matematik işlemini doğru hesaplayın.');
      setShowErrorModal(true);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Şifreler uyuşmuyor.');
      setShowErrorModal(true);
      return;
    }

    const payload = {
      firm_name: formData.companyName,
      first_name: formData.firstName,
      last_name: formData.lastName,
      mobile: formData.phone,
      email: formData.email,
      product_type: formData.productType,
      password: formData.password,
    };

    try {
      const apiUrl = process.env.REACT_APP_REGISTER_URL;
      const response = await fetch(`${apiUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setSuccessMessage('Üyelik kaydınız alındı, onay bekleniyor.');
        setShowSuccessModal(true);
        setError('');
      } else {
        setError(data.message || 'Bilinmeyen bir hata oluştu.');
        setShowErrorModal(true);
      }
    } catch (error) {
      setError('Bir hata oluştu, lütfen daha sonra tekrar deneyin.');
      setShowErrorModal(true);
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/');
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    window.scrollTo(0, 0); // Hata durumunda sayfayı en üste kaydır
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={6} lg={4} className="mx-auto">
          <div className="bg-light p-4 rounded shadow-sm">
            <h2 className="text-center mb-4">Üye Ol</h2>
            <Form onSubmit={handleRegister}>
              <Form.Group controlId="companyName" className="mb-3">
                <Form.Label>Firma Adı</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  placeholder="Firma adınızı girin"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="firstName" className="mb-3">
                <Form.Label>Ad</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Adınızı girin"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="lastName" className="mb-3">
                <Form.Label>Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Soyadınızı girin"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="phone" className="mb-3">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Telefon numaranızı girin"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Mail Adresi</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="E-posta adresinizi girin"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="productType" className="mb-3">
                <Form.Label>Ürün Tipi</Form.Label>
                <Form.Control as="select" name="productType" value={formData.productType} onChange={handleChange}>
                  <option value="Demo">Demo</option>
                  <option value="12 Aylık Paket">12 Aylık Paket</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Şifre</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Şifrenizi girin"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="confirmPassword" className="mb-4">
                <Form.Label>Şifre Tekrar</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Şifrenizi tekrar girin"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Doğrulama: {captchaNumbers.num1} + {captchaNumbers.num2} = ?</Form.Label>
                <Form.Control
                  type="number"
                  name="userCaptchaInput"
                  placeholder="Sonucu girin"
                  value={formData.userCaptchaInput}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Kaydet
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      {/* Başarı Modal'ı */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Başarılı</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Tamam
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Hata Modal'ı */}
      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Hata</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseErrorModal}>
            Tamam
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Signup;
