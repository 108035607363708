import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Yönlendirme için import et

const ForgotPassword = () => { 
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // useNavigate hook'u ile yönlendirme işlemi
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token'); // URL'den token'ı al

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    // Şifrelerin eşleşip eşleşmediğini kontrol et
    if (newPassword !== confirmPassword) {
      setError('Şifreler uyuşmuyor.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_FORGOT_PASSWORD_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, new_password: newPassword })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Şifre başarıyla güncellendi.');
        setError('');
        // 2 saniye sonra giriş ekranına yönlendirme
        setTimeout(() => {
          navigate('/'); // Giriş ekranına yönlendirme (Login sayfası)
        }, 2000);

      } else {
        setError(data.message || 'Bir hata oluştu.');
      }
    } catch (error) {
      setError('Bir hata oluştu, lütfen tekrar deneyin.');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <Form onSubmit={handleForgotPassword} className="w-50">
        <h2>Şifrenizi Sıfırlayın</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <Form.Group controlId="newPassword" className="mt-3">
          <Form.Label>Yeni Şifre</Form.Label>
          <Form.Control
            type="password"
            placeholder="Yeni şifrenizi girin"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" className="mt-3">
          <Form.Label>Şifreyi Onayla</Form.Label>
          <Form.Control
            type="password"
            placeholder="Şifrenizi tekrar girin"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary" className="mt-3">
          Şifreyi Sıfırla
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPassword;
